import Link from "components/link";
import Button from "components/button";
import Testimonial from "components/testimonial";

// TODO: add jest (https://github.com/vercel/next.js/tree/canary/examples/with-jest)
// TODO: loading pages? (https://github.com/vercel/next.js/tree/canary/examples/with-loading)
// TODO: add helmet (https://github.com/vercel/next.js/tree/canary/examples/with-react-helmet)
// TODO: add optimization (https://github.com/vercel/next.js/tree/canary/examples/with-why-did-you-render)
// TODO: optimize docker (https://github.com/vercel/next.js/blob/canary/examples/with-docker/Dockerfile)
// TODO: switch to preact? (next-plugin-preact)
// TODO: implement PWA (https://www.npmjs.com/package/next-pwa and https://github.com/vercel/next.js/blob/canary/examples/progressive-web-app)

export default function Home() {
  return (
    <>
      <section
        id="main"
        role="main"
        tabIndex="-1"
        className="container flex flex-col items-center content-center justify-center"
      >
        <div className="max-w-screen-lg py-8 space-y-4 text-center sm:space-y-8 xs:px-4 lg:py-12">
          <h1 className="font-sans text-3xl sm:text-4xl 2xl:text-6xl">
            Hey, I&apos;m Jonathan!
          </h1>
          <h2 className="text-4xl sm:text-6xl 2xl:text-8xl">
            Building digital products and handcrafted user experiences.
          </h2>
          <p className="text-3xl sm:text-4xl 2xl:text-6xl">
            I use code to solve problems and turn dreams into reality.
          </p>
          <Link href="/contact" containerClassName="no-underline pt-4">
            <Button
              aria-label="go to contact page"
              size="base"
              className="px-8"
            >
              Let&apos;s talk!
            </Button>
          </Link>
        </div>
      </section>
    </>
  );
}
