export default function Button({ children, size, className, ariaLabel }) {
  const options = {
    xs: "xs",
    sm: "sm",
    base: "base",
    lg: "lg",
    "2xl": "2xl",
    "3xl": "3xl",
    "4xl": "4xl",
    "5xl": "5xl",
    "6xl": "6xl",
    "7xl": "7xl",
    "8xl": "8xl",
    "9xl": "9xl",
  };
  return (
    <button
      aria-label={ariaLabel}
      className={`z-1transition-colors px-6 py-3 tracking-wide text-white uppercase bg-black dark:bg-indigo-600 text-${
        options[size] || "xs"
      } hover:bg-gray-900 hover:text-white ${className || ""}`}
    >
      {children}
    </button>
  );
}
