import Head from "next/head";
import Image from "next/image";
// import { blockquote } from "styles/blockquote.module.css";

// .blockquote:before,
// .blockquote:after {
//   font-family: FontAwesome;
//   position: absolute;
//   color: #039be5;
//   font-size: 34px;
// }

// .blockquote:before {
//   content: "\f10d";
//   top: -12px;
//   margin-right: -20px;
//   right: 100%;
// }

// .blockquote:after {
//   content: "\f10e";
//   margin-left: -20px;
//   left: 100%;
//   top: auto;
//   bottom: -20px;
// }

const Testimonial = ({ text, source }) => {
  return (
    <>
      <Head>
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css"
          key="font-awesome"
        ></link>
      </Head>
      <div className="border-4 border-gray-50">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="quote-left"
          className="absolute w-12 h-12 text-indigo-500"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
          ></path>
        </svg>
        <blockquote className={`relative w-full p-10 m-1 text-center`}>
          {text}
          <br />
          <cite> - {source}</cite>
        </blockquote>
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="quote-right"
          className="absolute w-12 h-12 text-indigo-500"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M464 32H336c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48zm-288 0H48C21.5 32 0 53.5 0 80v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48z"
          ></path>
        </svg>
      </div>
    </>
  );
};

export default Testimonial;
